var svgContainer = document.getElementById('svgContainer');
var animItem = bodymovin.loadAnimation({
  wrapper: svgContainer,
  animType: 'svg',
  loop: true,
  path: './lottie_loader.json'
});  

window.onload = function(){
  var el = document.querySelector('.preloader');
  setTimeout(function(){
    el.classList.add('fade');
    setTimeout(function(){
      el.classList.add('remove');
    }, 1000);
  }, 4000);
};

var LScroll = new LocomotiveScroll({
  el: document.querySelector(".smooth-scroll"),
  smooth: true,
  smoothMobile: true,
});


LScroll.on("scroll", ScrollTrigger.update);

ScrollTrigger.scrollerProxy(".smooth-scroll", {
  scrollTop(value) {   
    return arguments.length
      ? LScroll.scrollTo(value, 0, 0)
      : LScroll.scroll.instance.scroll.y;
  },
  getBoundingClientRect() {
    return {
      top: 0,
      left: 0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  },
  pinType: document.querySelector(".smooth-scroll").style.transform
    ? "transform"
    : "fixed"
});

function LottieScrollTrigger(vars) {
  let playhead = { frame: 0 },
    target = gsap.utils.toArray(vars.target)[0],
    speeds = { slow: "+=4000", medium: "+=1000", fast: "+=500" },
    st = {
      trigger: target,
      pin: false,
      start: "top bottom",
      end: speeds[vars.speed] || "+=1000",
      scrub: 1
    },
    animation = lottie.loadAnimation({
      container: target,
      renderer: vars.renderer || "svg",
      loop: false,
      autoplay: false,
      path: vars.path
    });
  for (let p in vars) {
    st[p] = vars[p];
  }

  animation.addEventListener("DOMLoaded", function () {
    gsap.to(playhead, {
      frame: animation.totalFrames - 1,
      ease: "expo",
      onUpdate: () => animation.goToAndStop(playhead.frame, true),
      scrollTrigger: st
    });     
    ScrollTrigger.refresh(); // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
  });
  return animation;
}

function init() {
  LottieScrollTrigger({
    target: "#opening-1-1",
    path: "./lottie_1.1.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 2 
  });
  LottieScrollTrigger({
    target: "#opening-1-2",
    path: "./lottie_1.2.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 4 
  });
  LottieScrollTrigger({
    target: "#opening-2-1",
    path: "./lottie_2.1.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 2
  });
  LottieScrollTrigger({
    target: "#opening-2-2",
    path: "./lottie_2.2.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 3
  });  
  LottieScrollTrigger({
    target: "#opening-3",
    path: "./lottie_3.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 1 
  });  
  LottieScrollTrigger({
    target: "#opening-6-1",
    path: "./lottie_6.1.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 2 
  });  
  LottieScrollTrigger({
    target: "#opening-6-2",
    path: "./lottie_6.2.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 3 
  }); 
  LottieScrollTrigger({
    target: "#opening-6-3",
    path: "./lottie_6.3.json",
    scroller: ".smooth-scroll",
    speed: "medium",
    duration: "10",
    scrub: 2 
  });   
  LottieScrollTrigger({
    target: "#opening-7-1",
    path: "./lottie_7.1.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 2
  }); 
  LottieScrollTrigger({
    target: "#opening-7-2",
    path: "./lottie_7.2.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 3 
  }); 
  LottieScrollTrigger({
    target: "#opening-7-3",
    path: "./lottie_7.3.json",
    scroller: ".smooth-scroll",
    speed: "slow",
    duration: "30",
    scrub: 2 
  });          
  ScrollTrigger.refresh();
}

window.addEventListener("load", function (event) {
  init();
  ScrollTrigger.refresh();
});

// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
ScrollTrigger.addEventListener("refresh", () => LScroll.update());

// after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
ScrollTrigger.refresh();

// Read more
document.querySelector('#readmore').addEventListener('click', function(){
  document.querySelector('.long__fold').classList.add('selected');
  document.querySelector('.long__fold').style.height = 'auto';
  this.style.display = 'none';
  document.querySelector('.load__more-back-top').style.display = 'block';
  document.querySelector('.long__fold').style.overflow = 'visible';
  ScrollTrigger.refresh();
}); 

var commt = document.querySelector('#section-hero').getBoundingClientRect().top;

document.querySelector('.trigger-top').addEventListener('click', function() {
  LScroll.scrollTo(commt, 0, 0);
});

var lastScroll = 0;
var triggerDisc = false;

document.querySelector('#triggerDiscovery').addEventListener('click', function(){
  triggerDisc = true;
});   

LScroll.on("scroll", function(ev){
  var currentScroll = ev.scroll.y;
  setTimeout(function(){
    if(currentScroll < lastScroll) {
      document.querySelector('.nav').classList.add('hide');
      document.querySelector('#shareMobile').style.display = 'none';
    } else {
      document.querySelector('.nav').classList.remove('hide');
    }  
  }, 100); 
  lastScroll = currentScroll;
});
 

var clip = new ClipboardJS('.btn-clipboard');

document.querySelector('#closeShare').addEventListener('click', function(){
  document.querySelector('#shareMobile').style.display = 'none';
});

document.querySelector('#showShare').addEventListener('click', function(){
  document.querySelector('#shareMobile').style.display = 'flex';
});

runCounterAnimation();


function runCounterAnimation() {

  Number.prototype.numberFormat = function(decimals, dec_point, thousands_sep) {
    dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
    thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

    var parts = this.toFixed(decimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
  }

  var counter = { var: 0 };
  var hadCount = false;
  var usr = document.getElementById("count-users");
  var msme = document.getElementById("count-msme");
  var communitySec = document.getElementById('section-family').getBoundingClientRect().top;

  LScroll.on("scroll", function(ev){
    if (ev.scroll.y >= communitySec && !hadCount) {
      TweenMax.to(counter, 3, {
        var: 100, 
        onUpdate: function () {
          var nwc = counter.var.numberFormat(0);
          usr.innerHTML = nwc;
        },
        ease:Power4.easeOut
      });
  
      TweenMax.to(counter, 3, {
        var: 13.5, 
        onUpdate: function () {
          var nwc = counter.var.numberFormat(1);
          msme.innerHTML = nwc;
        },
        ease: Power4.easeOut
      });

      hadCount = true;
    } 
  });  
}

Splitting();

// LScroll.on("scroll", function(ev){
//   var curScroll = ev.scroll.y;
//   var opening1 = document.querySelector('.opening--1');
//   var openingText1 = document.querySelector('.text-typing--1');
//   var opening2 = document.querySelector('.opening--2');
//   var openingText2 = document.querySelector('.text-typing--2');
//   var opening3 = document.querySelector('.opening--3');
//   var openingText3 = document.querySelector('.text-typing--3');
//   var opening4 = document.querySelector('.community');
//   var openingText4 = document.querySelector('.text-typing--4');
//   var opening5 = document.querySelector('.community');
//   var openingText5 = document.querySelector('.text-typing--5');
//   var opening6 = document.querySelector('.closing--1');
//   var openingText6 = document.querySelector('.text-typing--6');
//   var opening7 = document.querySelector('.closing--1');
//   var openingText7 = document.querySelector('.text-typing--7');
//   var opening8 = document.querySelector('.closing--2');
//   var openingText8 = document.querySelector('.text-typing--8');
//   var opening9 = document.querySelector('.long');
//   var openingText9 = document.querySelector('.text-typing--9');   
//   var opening10 = document.querySelector('.community');
//   var openingText10 = document.querySelector('.text-typing--10');           

//   if (curScroll > opening1.getBoundingClientRect().top) {
//     openingText1.classList.add('active');
//   }

//   if (curScroll > opening2.getBoundingClientRect().top) {
//     openingText2.classList.add('active');
//   } 
  
//   if (curScroll > opening3.getBoundingClientRect().bottom) {
//     openingText3.classList.add('active');
//   }   

//   if (curScroll > opening8.getBoundingClientRect().bottom) {
//     openingText8.classList.add('active');
//   }     
// });

document.querySelectorAll(".text-typing").forEach((image) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: image,
      once: true,
      scroller: ".smooth-scroll",
      toggleClass: 'active',
      scrub: true,
      end: "+=200%"
    }
  });

  tl;

    // tl.set(
    //     image, {
    //       autoAlpha: 0,
    //       scale: 0.1
    //     }).fromTo(
    //       image, {
    //         autoAlpha: 0
    //       }, {
    //         autoAlpha: 1,
    //         scale: 1
    //       }
    //     )
    //   .to(
    //     image, { 
    //       autoAlpha: 0, 
    //       scale: 1 
    //     });
});